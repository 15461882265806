import React from "react"

import { Layout, Logo, SEO } from "../components"

const IndexPage = () => (
  <Layout>
    <SEO />

    <Logo />
  </Layout>
)

export default IndexPage
